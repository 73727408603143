import './Home.scss';

import { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Axios from '../../Utils/Axios';

function Home() {
  const [homeLinks, setHomeLinks] = useState();
  const cardRefs = useRef([]);
  const glowRefs = useRef([]);
  
   const orderLinks = [4, 6, 3, 1, 5];
  // const orderLinks = [6, 7, 8, 9, 10, 11];

  const newOrderLinks = orderLinks.map(
    (id) => homeLinks && homeLinks.find((item) => item.id === id)
  );

  const rotateToMouse = (e, index) => {
    const card = cardRefs.current[index];
    const glowElement = glowRefs.current[index];
    if (!card || !glowElement) return;
    

    const mouseX = e.clientX;
    const mouseY = e.clientY;
    const bounds = card.getBoundingClientRect();
    const leftX = mouseX - bounds.x;
    const topY = mouseY - bounds.y;
    const center = {
      x: leftX - bounds.width / 2,
      y: topY - bounds.height / 2,
    };
    const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

    card.style.transform = `
      scale3d(1.07, 1.07, 1.07)
      rotate3d(
          ${center.y / 100},
          ${-center.x / 100},
          0,
          ${Math.log(distance) * 2}deg
      )
    `;

    glowElement.style.backgroundImage = `
      radial-gradient(
          circle at
          ${center.x * 2 + bounds.width / 2}px
          ${center.y * 2 + bounds.height / 2}px,
          #d198536b,
          #0000000f
      )`;
  };

  useEffect(() => {
    Axios
      .get('/homes?populate=*')
      .then((res) => {
        setHomeLinks(res.data.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, []);

  const handleEvent = (e, index) => {
    cardRefs.current[index] = e.currentTarget;

    glowRefs.current[index] = e.currentTarget.querySelector('.glow');
    e.currentTarget.addEventListener('mousemove', (event) =>
      rotateToMouse(event, index)
    );
  };

  const handleLeave = (e, index) => {
    const card = cardRefs.current[index];
    const glowElement = glowRefs.current[index];
    if (!card || !glowElement) return;

    card.removeEventListener('mousemove', (event) =>
      rotateToMouse(event, index)
    );

    glowElement.style.backgroundImage = '';
    card.style.transform = '';
  };

  return (
    <div className="Home">
      <Helmet>
        <title>Home</title>
        <meta name="description" content="Home | Robot Traders" />
      </Helmet>
      <h1 className='Home-title'>Home</h1>
      <nav className="Home-cards">
      {homeLinks && newOrderLinks.map((link) => (
        <NavLink
          key={link.attributes.title}
          to={link.attributes.link}
          className="Home-cards_one"
          onMouseEnter={(e) => handleEvent(e, 0)}
          onMouseLeave={(e) => handleLeave(e, 0)}
        >
          <div className="glow" />
          <div className="img" style={{backgroundImage: `url('https://hammerhead-app-atzdr.ondigitalocean.app${link.attributes.Picture.data.attributes.url}')` }}/>
          <h1>{link.attributes.title}</h1>
        </NavLink>
      ))}
      </nav>
    </div>
  );
}

export default Home;

