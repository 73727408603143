import './Courses.scss';

import { useEffect, useState } from 'react';

import Cards from '../../Components/Cards/Cards';
import Axios from '../../Utils/Axios';

import arrow from '../../Components/Assets/arrow.png'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Courses() {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    Axios
      .get('/courses?populate=*')
      .then((res) => {
        setLinks(res.data.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, []);
  return (
    <div className="Courses">
      <Helmet>
        <title>Courses</title>
        <meta name="description" content="Courses | Robot Traders" />
      </Helmet>
      <h1 className='Courses-title'>Courses</h1>
      <Cards Links={links} />
      <Link to='/'><img src={arrow} style={{width : '40px'}}/></Link>
    </div>
  );
}

export default Courses;
