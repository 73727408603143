import './PageBot.scss';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactMarkdown from 'react-markdown';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Axios from '../../Utils/Axios';
import arrow from '../../Components/Assets/arrow.png'
import { Link } from 'react-router-dom';

function PageBot() {
  const [bot, setBot] = useState([]);
  const { slug } = useParams();

  const youtubeLink = bot.youtube;

  useEffect(() => {
    Axios.get(`/trading-bots/${slug}?populate=*`)
      .then((res) => {
        setBot(res.data.data.attributes);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, []);
  return (
    <div className="PageBot">
      <h1>{bot.title}</h1>
      <ReactMarkdown
        className="PageBot-content"
        children={bot.text}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            const codeContent = String(children).replace(/\n$/, '');
            if (!inline && match) {
              const clipboardId = `code-clipboard-${Math.random()}`.replace(
                /\./g,
                '_'
              );

              const handleClick = () => {
                const codeElement = document.getElementById(clipboardId);
                toast.success('Copied to clipboard!', {
                  position: 'bottom-center',
                  autoClose: 1000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  theme: 'dark',
                });

                codeElement.select(); // Sélectionnez le texte dans le textarea
                document.execCommand('copy'); // Copiez le texte
              };

              return (
                <div>
                  <SyntaxHighlighter
                    {...props}
                    children={codeContent}
                    style={dracula}
                    language={match[1]}
                    PreTag="div"
                  />
                  <button onClick={handleClick} className="Copy">
                    Copy
                  </button>
                  <textarea
                    id={clipboardId}
                    style={{ position: 'absolute', left: '-9999px' }}
                    readOnly
                    value={codeContent}
                  />
                </div>
              );
            } else {
              return (
                <code {...props} className={className}>
                  {children}
                </code>
              );
            }
          },
        }}
      />
      <div className="PageBot-youtube">
        {youtubeLink &&
          youtubeLink.length > 0 &&
          youtubeLink.map((video) => (
            <iframe
              width="480"
              height="265"
              src={`https://www.youtube.com/embed/${video.youtubeLink}`}
              title={video.title}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          ))}
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="dark"
      />
      <Link to="/trading-bots">
        <img src={arrow} style={{ width: '40px' }} />
      </Link>
    </div>
  );
}

export default PageBot;
