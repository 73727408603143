import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Articles.scss';
import Axios from '../../Utils/Axios';
import arrow from '../../Components/Assets/arrow.png';
import { Helmet } from 'react-helmet';

function Articles() {
  const [articles, setArticles] = useState();

  useEffect(() => {
    Axios.get('/articles?sort[0]=createdAt:desc&populate[0]=picture')
      .then((res) => {
        setArticles(res.data.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, []);

  return (
    <div className="Articles">
      <Helmet>
        <title>Articles</title>
        <meta name="description" content="Articles | Robot Traders" />
      </Helmet>
      {articles &&
        articles.map((art) => (
          <Link
            key={art.id}
            to={`/blog/${art.attributes.slug}`}
            className="Articles-link"
          >
              <img
                src={`https://hammerhead-app-atzdr.ondigitalocean.app${art.attributes.picture.data.attributes.formats.thumbnail.url}`}
              />
            <div className="Articles-link-text">
              <h4>{art.attributes.title}</h4>
              <span>{art.attributes.publishedAt.slice(0, 10)}</span>
              <p>{art.attributes.description}</p>
            </div>
          </Link>
        ))}
      <Link to="/" className="Articles-back">
        <img src={arrow} style={{ width: '40px' }} />
      </Link>
    </div>
  );
}

export default Articles;
