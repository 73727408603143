// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyLayout {
  display: grid;
  height: auto;
  grid-template-rows: auto 1fr auto;
}`, "",{"version":3,"sources":["webpack://./src/Components/MyLayout.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,iCAAA;AACJ","sourcesContent":[".MyLayout {\n    display: grid;\n    height: auto;\n    grid-template-rows: auto 1fr auto;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
