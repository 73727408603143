import './ChapterCourse.scss';
import Axios from '../../Utils/Axios';

import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ChapterCourse() {
  const [chapter, setChapter] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const { id } = useParams();
  const { idChapter } = useParams();

  const navLinkStyles = ({ isActive }) => {
    return {
      fontWeight: isActive ? 'bold' : '',
      textShadow: isActive ? "0 0 7px #f3a03ad1," +
      "0 0 10px #f3a03ad1," +
      "0 0 21px #f3a03ad1," +
      "0 0 42px #f3a03ad1," +
      "0 0 82px #f3a03ad1," +
      "0 0 92px #f3a03ad1," +
      "0 0 102px #f3a03ad1," +
      "0 0 151px #f3a03ad1" : '',
      width: isActive ? '50px' : '',
    };
  };

  useEffect(() => {
    const numericIdChapter = parseInt(idChapter);
    setFiltered(
      chapter.attributes &&
        chapter.attributes.course.filter((item) => item.id === numericIdChapter)
    );
  }, [chapter, idChapter]);

  useEffect(() => {
    Axios
      .get(`/courses/${id}?populate=course`)
      .then((res) => {
        setChapter(res.data.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, []);

  return (
    <div className="ChapterCourse">
      <nav className="ChapterCourse-nav">
        <NavLink to={`/course/${id}`}>
          {'<'}
          <p>Main menu</p>
        </NavLink>
        {chapter.attributes &&
          chapter.attributes.course.map((item, i) => (
            <NavLink key={item.id} to={`/course/${id}/chapter/${item.id}`} style={navLinkStyles}>
              {i}
              <p>{item.title}</p>
            </NavLink>
          ))}
      </nav>
      {filtered && filtered.length > 0 ? (
        <>
          <h1>{filtered[0].title}</h1>
          <ReactMarkdown
            className="ChapterCourse-content"
            children={filtered[0].content}
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '');
                const codeContent = String(children).replace(/\n$/, '');
                if (!inline && match) {
                  const clipboardId = `code-clipboard-${Math.random()}`.replace(
                    /\./g,
                    '_'
                  );

                  const handleClick = () => {
                    const codeElement = document.getElementById(clipboardId);
                    toast.success('Copied to clipboard!', {
                      position: 'bottom-center',
                      autoClose: 1000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: false,
                      draggable: true,
                      progress: undefined,
                      theme: 'dark',
                    });
                    codeElement.select(); 
                    document.execCommand('copy'); 
                  };
                  
                  return (
                    <div>
                      <SyntaxHighlighter
                        {...props}
                        children={codeContent}
                        style={dracula}
                        language={match[1]}
                        PreTag="div"
                      />
                      <button onClick={handleClick} className="Copy">
                        Copy
                      </button>
                      <textarea
                        id={clipboardId}
                        style={{ position: 'absolute', left: '-9999px' }}
                        readOnly
                        value={codeContent}
                      />
                    </div>
                  );
                } else {
                  return (
                    <code {...props} className={className}>
                      {children}
                    </code>
                  );
                }
              },
            }}
          />
        </>
      ) : (
        <p>Loading...</p> 
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="dark"
      />
    </div>
  );
}

export default ChapterCourse;
