// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Courses {
  min-height: 82.3vh;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 2rem;
  flex-direction: column;
}
.Courses-title {
  color: #dad0f3;
  text-align: center;
}
.Courses a {
  border-radius: 999;
}
.Courses a img {
  display: flex;
  margin: auto;
  margin-top: 3rem;
  border-radius: 999px;
}
.Courses a img:hover {
  transform: rotate(-360deg) scale(1.1);
  transition: 0.5s transform;
  box-shadow: 0 1px 5px rgba(243, 160, 58, 0.8196078431), 0 1px 15px rgba(243, 160, 58, 0.8196078431), 0 1px 25px rgba(243, 160, 58, 0.8196078431), 0 1px 35px rgba(209, 152, 83, 0.4196078431);
}

@media (min-width: 575px) {
  .Courses-title {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Courses/Courses.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EAEA,aAAA;EACA,aAAA;EACA,sBAAA;AAAF;AACE;EACE,cAAA;EACA,kBAAA;AACJ;AACE;EACE,kBAAA;AACJ;AACI;EACE,aAAA;EACA,YAAA;EACA,gBAAA;EACA,oBAAA;AACN;AAEI;EACE,qCAAA;EACA,0BAAA;EACA,6LAAA;AAAN;;AAKA;EAEI;IACE,aAAA;EAHJ;AACF","sourcesContent":[".Courses {\n  min-height: 82.3vh;\n  width: 100%;\n  height: 100%;\n  // margin: auto;\n  display: flex;\n  padding: 2rem;\n  flex-direction: column;\n  &-title{\n    color: #dad0f3;\n    text-align: center;\n  }\n  a{\n    border-radius: 999;\n\n    img{\n      display: flex;\n      margin: auto;\n      margin-top: 3rem;\n      border-radius: 999px;\n\n    }\n    img:hover{\n      transform: rotate(-360deg) scale(1.1);\n      transition: 0.5s transform;\n      box-shadow: 0 1px 5px #f3a03ad1, 0 1px 15px #f3a03ad1, 0 1px 25px #f3a03ad1,\n      0 1px 35px #d198536b;\n    }\n  }\n}\n@media (min-width: 575px){\n  .Courses{\n    &-title{\n      display: none;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
