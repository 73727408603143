import axios from 'axios';

const Axios = axios.create({
  baseURL: 'https://hammerhead-app-atzdr.ondigitalocean.app/api',
  // baseURL: 'http://localhost:1337/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

export default Axios;
