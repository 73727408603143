// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageBot {
  max-width: 1200px;
  min-height: 82.3vh;
  width: 100%;
  margin: auto;
  color: #fff;
  padding: 2rem 1rem;
}
.PageBot a {
  border-radius: 999;
}
.PageBot a img {
  display: flex;
  margin: auto;
  margin-top: 3rem;
  border-radius: 999px;
}
.PageBot a img:hover {
  transform: rotate(-360deg) scale(1.1);
  transition: 0.5s transform;
  box-shadow: 0 1px 5px rgba(243, 160, 58, 0.8196078431), 0 1px 15px rgba(243, 160, 58, 0.8196078431), 0 1px 25px rgba(243, 160, 58, 0.8196078431), 0 1px 35px rgba(209, 152, 83, 0.4196078431);
}
.PageBot h1 {
  display: flex;
  justify-content: center;
  margin: 2rem;
}
.PageBot-content a {
  color: rgba(243, 160, 58, 0.8196078431);
}
.PageBot-content pre {
  position: relative;
}
.PageBot-youtube {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
}
.PageBot-youtube iframe {
  margin-bottom: 1rem;
  width: 330px;
  height: 185px;
}

@media (min-width: 575px) {
  .PageBot {
    width: 800px;
    font-size: 1.2rem;
    margin: auto;
  }
  .PageBot-youtube {
    display: flex;
    flex-wrap: wrap;
  }
  .PageBot-youtube iframe {
    width: 480px;
    height: 265px;
    margin: 5px 5px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/PageBot/PageBot.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;AACF;AAAE;EACE,kBAAA;AAEJ;AAAI;EACE,aAAA;EACA,YAAA;EACA,gBAAA;EACA,oBAAA;AAEN;AACI;EACE,qCAAA;EACA,0BAAA;EACA,6LAAA;AACN;AAGE;EACE,aAAA;EACA,uBAAA;EACA,YAAA;AADJ;AAII;EACE,uCAAA;AAFN;AAII;EACE,kBAAA;AAFN;AAKE;EACE,aAAA;EACA,eAAA;EACA,uBAAA;EACA,gBAAA;AAHJ;AAKI;EACE,mBAAA;EACA,YAAA;EACA,aAAA;AAHN;;AAUA;EACE;IACE,YAAA;IACA,iBAAA;IACA,YAAA;EAPF;EAQE;IACE,aAAA;IACA,eAAA;EANJ;EAOI;IACI,YAAA;IACA,aAAA;IACA,eAAA;EALR;AACF","sourcesContent":[".PageBot {\n  max-width: 1200px;\n  min-height: 82.3vh;\n  width: 100%;\n  margin: auto;\n  color: #fff;\n  padding: 2rem 1rem;\n  a{\n    border-radius: 999;\n\n    img{\n      display: flex;\n      margin: auto;\n      margin-top: 3rem;\n      border-radius: 999px;\n\n    }\n    img:hover{\n      transform: rotate(-360deg) scale(1.1);\n      transition: 0.5s transform;\n      box-shadow: 0 1px 5px #f3a03ad1, 0 1px 15px #f3a03ad1, 0 1px 25px #f3a03ad1,\n      0 1px 35px #d198536b;\n    }\n  }\n  h1 {\n    display: flex;\n    justify-content: center;\n    margin: 2rem;\n  }\n  &-content {\n    a {\n      color: #f3a03ad1 ;\n    }\n    pre {\n      position: relative;\n    }\n  }\n  &-youtube {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    margin-top: 2rem;\n\n    iframe {\n      margin-bottom: 1rem;\n      width:330px;\n      height: 185px;\n    }\n  }\n}\n//480 px -> 336\n//265 -> 185.5\n\n@media (min-width: 575px) {\n  .PageBot {\n    width: 800px;\n    font-size: 1.2rem;\n    margin: auto;\n    &-youtube {\n      display: flex;\n      flex-wrap: wrap;\n      iframe{\n          width:480px;\n          height: 265px;\n          margin: 5px 5px;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
