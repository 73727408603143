import React, { useEffect, useRef } from 'react';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import './MyLayout.scss';

export function MyLayout({ children }) {
  const layoutRef = useRef(null);

  useEffect(() => {
    const layout = layoutRef.current;

    if (!layout) {
      return;
    }

    const handleMouseMove = (e) => {
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      const rect = layout.getBoundingClientRect();

      const percentageX = ((mouseX - rect.left) / rect.width) * 100;
      const percentageY = ((mouseY - rect.top) / rect.height) * 100;

      layout.style.background = `
        radial-gradient(circle at ${percentageX}% ${percentageY}%, rgb(38, 15, 84) -30%, black 40%)
      `;
    };

    layout.addEventListener('mousemove', handleMouseMove);

    return () => {
      layout.removeEventListener('mousemove', handleMouseMove);
    };
  }, [layoutRef]);

  return (
    <div className="MyLayout" ref={layoutRef}>
      <Header />
      {children}
      <Footer />
    </div>
  );
}
