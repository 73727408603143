import './App.scss';
import { Route, Routes } from 'react-router-dom';
import { MyLayout } from '../MyLayout';
import Home from '../../Pages/Home/Home';
import TradingBot from '../../Pages/TradingBot/TradingBot';
import HireUs from '../../Pages/HireUs/HireUs';
import Courses from '../../Pages/Courses/Courses';
import OneCourse from '../../Pages/OneCourse/OneCourse';
import ChapterCourse from '../../Pages/ChapterCourse/ChapterCourse';
import PageBot from '../../Pages/PageBot/PageBot';
import Partner from '../../Pages/Partner/Partner';
import Articles from '../../Pages/Articles/Articles';
import OneArticle from '../../Pages/OneArticle/OneArticle';
// import Axios from '../../Utils/Axios';


function App() {

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <MyLayout>
              <Home />
            </MyLayout>
          }
        />
        <Route
          path="/trading-bots"
          element={
            <MyLayout>
              <TradingBot />
            </MyLayout>
          }
        />
        <Route
          path="/trading-bots/:slug"
          element={
            <MyLayout>
              <PageBot />
            </MyLayout>
          }
        />
        <Route
          path="/hire-me"
          element={
            <MyLayout>
              <HireUs />
            </MyLayout>
          }
        />
        <Route
          path="/courses"
          element={
            <MyLayout>
              <Courses />
            </MyLayout>
          }
        />
        <Route
          path="/courses/:slug"
          element={
            <MyLayout>
              <OneCourse />
            </MyLayout>
          }
        />
        <Route
          path="/blog"
          element={
            <MyLayout>
              <Articles />
            </MyLayout>
          }
        />
        <Route
          path="/blog/:slug"
          element={
            <MyLayout>
              <OneArticle />
            </MyLayout>
          }
        />
        <Route
          path="/offers"
          element={
            <MyLayout>
              <Partner />
            </MyLayout>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
