import './Header.scss';

import youtube from '../../Components/Assets/youtube.png';
import discord from '../../Components/Assets/discorde.png';
import twitter from '../../Components/Assets/twitter.png';
import github from '../../Components/Assets/github.png';
import menu from '../../Components/Assets/menu.png';
import mail from '../../Components/Assets/mail.png';
import home from '../../Components/Assets/home.png';

import { Link, NavLink, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Axios from '../../Utils/Axios';

function Header() {
  const [headerLinks, setHeaderLinks] = useState([]);
  const [open, setOpen] = useState(false);
  const [transform, setTransform] = useState('');
  const [rotate, setRotate] = useState('');
  const [displayNone, setDisplayNone] = useState('none');

  const orderLinks = [4, 6, 3, 1, 5];

  // const orderLinks = [6, 7, 8, 9, 10, 11];
  const newOrderLinks = orderLinks.map(
    (id) => headerLinks && headerLinks.find((item) => item.id === id)
  );
  
  const location = useLocation();

  const inHome = location.pathname !== '/';

  const blur = open ? '#000000b0' : '';
  const display = open ? 'block' : 'none';

  const navLinkStyles = ({ isActive }) => {
    return {
      fontWeight: isActive ? 'bold' : '',
      textShadow: isActive
        ? '0 0 7px #f3a03ad1,' +
          '0 0 10px #f3a03ad1,' +
          '0 0 21px #f3a03ad1,' +
          '0 0 42px #f3a03ad1,' +
          '0 0 82px #f3a03ad1,' +
          '0 0 92px #f3a03ad1,' +
          '0 0 102px #f3a03ad1,' +
          '0 0 151px #f3a03ad1'
        : '',
    };
  };

  useEffect(() => {
    Axios.get('/homes?populate=*')
      .then((res) => {
        setHeaderLinks(res.data.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, []);

  const handleOpen = () => {
    if (open) {
      setTransform('translateX(0px)');
      setRotate('rotate(0deg)');
      setTimeout(() => {
        setDisplayNone('none');
      }, 500);
    } else {
      setDisplayNone('flex');
      setRotate('rotate(90deg)');
      setTimeout(() => {
        setTransform('translateX(-200px)');
      }, 50);
    }
    setOpen(!open);
  };

  return (
    <div className="Header">
      <Link to="/" style={{ textDecoration: 'none' }}>
        <h1 className="Header-title">Robot Traders</h1>
      </Link>
      <div className="Header-icon">
        <a href="https://www.youtube.com/@robottraders" target="_blank">
          <img src={youtube} />
        </a>
        <a href="https://twitter.com/Robot_Traders" target="_blank">
          <img src={twitter} />
        </a>
        <a href="https://discord.gg/aQJ9PvC2Hz" target="_blank">
          <img src={discord} />
        </a>
        <a href="https://github.com/RobotTraders" target="_blank">
          <img src={github} />
        </a>
        <a href="mailto:robot.traders@protonmail.com">
          <img src={mail} />
        </a>
      </div>
      <nav className="Header-navlink">
        <h1 className="Header-title-two">Robot Traders</h1>
        {inHome && (
          <div>
            <NavLink to="/" style={navLinkStyles}>
              <img src={home} style={{ width: '25px' }} />
            </NavLink>
          </div>
        )}
        <div className="Header-navlink-dynamic">
          {headerLinks.length > 0 &&
            newOrderLinks.map((link) => (
              <NavLink
                to={`${link.attributes.link}`}
                style={navLinkStyles}
                key={link.id}
              >
                {link.attributes.title}
              </NavLink>
            ))}
        </div>
        <img
          style={{ transform: rotate }}
          src={menu}
          className="Header-navlink-burger"
          onClick={() => {
            handleOpen();
          }}
        />
      </nav>
      <aside
        className="Header-aside"
        style={{ transform: transform, display: displayNone }}
      >
        <NavLink to="/" style={navLinkStyles} onClick={() => handleOpen()}>
          Home
        </NavLink>
        {headerLinks.map((link) => (
          <NavLink
            to={link.attributes.link}
            style={navLinkStyles}
            onClick={() => handleOpen()}
          >
            {link.attributes.title}
          </NavLink>
        ))}
      </aside>
      <div
        className="blur"
        style={{ background: blur, display: display }}
        onClick={() => handleOpen()}
      />
    </div>
  );
}

export default Header;
