import './HireUs.scss';

import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import emoji from 'remark-emoji';
import arrow from '../../Components/Assets/arrow.png'
import { Link } from 'react-router-dom';
import Axios from '../../Utils/Axios';
import { Helmet } from 'react-helmet';

function HireUs() {
  const [content, setContent] = useState([]);

  useEffect(() => {
    Axios.get('/hire-uses?populate=*')
      .then((res) => {
        setContent(res.data.data[0]);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className="HireUs">  
      <Helmet>
        <title>Hire Us</title>
        <meta name="description" content="Hire Us | Robot Traders" />
      </Helmet>
      <h1 className="HireUs-title">Hire us</h1>
      <div className='HireUs-content'>
        {content.attributes && content.attributes.content.length > 0 ? (
          <ReactMarkdown remarkPlugins={[gfm, emoji]} className="HireUs-section">
            {content.attributes.content}
          </ReactMarkdown>
        ) : (
          <p className="Loading">Loading</p>
        )}
        <aside className="HireUs-aside">
          <h2>Previous Jobs</h2>
          {content.attributes &&
            content.attributes.job.map((job) => (
              <div>
                <h3>{job.title}</h3>
                <p>{job.description}</p>
              </div>
            ))}
        </aside>
      </div>
      <Link to='/'><img src={arrow} style={{width : '40px'}}/></Link>

    </div>
  );
}

export default HireUs;
