import './Footer.scss';

function Footer(){
    return(
        <div className="Footer">
            <p>&copy; Robot Traders 2023 - All Rights reserved</p>
        </div>
    )
}

export default Footer;
