// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer p {
  color: #481db4;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer/Footer.scss"],"names":[],"mappings":"AACI;EACI,cAAA;EACA,kBAAA;AAAR","sourcesContent":[".Footer{\n    p{\n        color: #481db4;\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
