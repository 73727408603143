// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Partner {
  max-width: 1500px;
  width: 100%;
  min-height: 82.3vh;
  margin: auto;
  padding: 5rem 2rem 2rem 2rem;
  color: #fff;
}
.Partner-section {
  margin-bottom: 1rem;
}
.Partner-section p {
  width: 80%;
}
.Partner-section a {
  width: 80% !important;
  color: rgba(243, 160, 58, 0.8196078431);
}
.Partner a {
  border-radius: 999;
}
.Partner a img {
  display: flex;
  margin: auto;
  margin-top: 3rem;
  border-radius: 999px;
}
.Partner a img:hover {
  transform: rotate(-360deg) scale(1.1);
  transition: 0.5s transform;
  box-shadow: 0 1px 5px rgba(243, 160, 58, 0.8196078431), 0 1px 15px rgba(243, 160, 58, 0.8196078431), 0 1px 25px rgba(243, 160, 58, 0.8196078431), 0 1px 35px rgba(209, 152, 83, 0.4196078431);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Partner/Partner.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;EACA,4BAAA;EACA,WAAA;AACF;AAAE;EAIE,mBAAA;AADJ;AAFI;EACE,UAAA;AAIN;AADI;EACE,qBAAA;EACA,uCAAA;AAGN;AAAE;EACE,kBAAA;AAEJ;AAAI;EACE,aAAA;EACA,YAAA;EACA,gBAAA;EACA,oBAAA;AAEN;AAAI;EACE,qCAAA;EACA,0BAAA;EACA,6LAAA;AAEN","sourcesContent":[".Partner {\n  max-width: 1500px;\n  width: 100%;\n  min-height: 82.3vh;\n  margin: auto;\n  padding: 5rem 2rem 2rem 2rem;\n  color: #fff;\n  &-section {\n    p {\n      width: 80%;\n    }\n    margin-bottom: 1rem;\n    a {\n      width: 80% !important;\n      color: #f3a03ad1;\n    }\n  }\n  a {\n    border-radius: 999;\n\n    img {\n      display: flex;\n      margin: auto;\n      margin-top: 3rem;\n      border-radius: 999px;\n    }\n    img:hover {\n      transform: rotate(-360deg) scale(1.1);\n      transition: 0.5s transform;\n      box-shadow: 0 1px 5px #f3a03ad1, 0 1px 15px #f3a03ad1,\n        0 1px 25px #f3a03ad1, 0 1px 35px #d198536b;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
