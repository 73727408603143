import './Partner.scss';
import arrow from '../../Components/Assets/arrow.png';
import { Link } from 'react-router-dom';

import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import emoji from 'remark-emoji';

import Axios from '../../Utils/Axios';
import { Helmet } from 'react-helmet';

function Partner() {
    const [markdownText, setMarkdownText] = useState('');

    useEffect(() => {
      Axios.get('/bonuses?populate=*')
        .then((res) => {
          setMarkdownText(res.data.data[0]?.attributes.description || '');
        })
        .catch((err) => {
          console.error(err);
        });
    }, []);

  return (
    <div className="Partner">
      <Helmet>
        <title>Partner</title>
        <meta name="description" content="Partner | Robot Traders" />
      </Helmet>
      <ReactMarkdown remarkPlugins={[gfm, emoji]} className="Partner-section">
        {markdownText}
      </ReactMarkdown>
      <Link to="/">
        <img src={arrow} style={{ width: '40px' }} />
      </Link>
    </div>
  );
}

export default Partner;
