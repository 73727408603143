import './TradingBot.scss';

import { useEffect, useState } from 'react';

import Cards from '../../Components/Cards/Cards';
import Axios from '../../Utils/Axios';

import arrow from '../../Components/Assets/arrow.png'
import { Link } from 'react-router-dom';

import { Helmet } from 'react-helmet';

function TradingBot() {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    Axios
      .get('/trading-bots?populate=*')
      .then((res) => {
        setLinks(res.data.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, []);

  return (
    <div className="TradingBot">
      <Helmet>
        <title>Trading Bots</title>
        <meta name="description" content="Trading Bots | Robot Traders" />
      </Helmet>
      <h1 className='TradingBot-title'>Trading Bots</h1>
      <Cards Links={links} />
      <Link to='/'><img src={arrow} style={{width : '40px'}}/></Link>
    </div>
  );
}

export default TradingBot;
