import './OneArticle.scss';
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Axios from '../../Utils/Axios';
import arrow from '../../Components/Assets/arrow.png';
import ReactMarkdown from 'react-markdown';
import rehypeReact from 'rehype-react';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  faClock,
  faCalendarDays,
  faRobot,
} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import { remark } from 'remark';
import strip from 'strip-markdown';
import { Helmet } from 'react-helmet';
import YouTubeEmbed from '../../Components/youtube';

function OneArticle() {
  const [article, setArticle] = useState();
  const { slug } = useParams();

  useEffect(() => {
    Axios.get(`/articles/${slug}?populate=*`)
      .then((res) => {
        setArticle(res.data.data && res.data.data.attributes);
        console.log(res.data.data && res.data.data.attributes);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, []);

  const estimateTime = (mdContent) => {
    const plainTextContent = remark()
      .use(strip)
      .processSync(mdContent)
      .toString();

    const words = plainTextContent.split(/\s+/).length;
    const readingTimeMinutes = Math.ceil(words / 220); 

    return readingTimeMinutes;
  };

  const readingTime = estimateTime(article && article.article);

  const day = dayjs(article && article.publishedAt).locale('en').format('DD MMMM YYYY')

  return (
    <div className="OneArticle">
      <Helmet>
        <title>{article && article.title}</title>
        <meta name="description" content={article && article.description} />
        <meta property="og:title" content={article && article.title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://robottraders.io/articles/${slug}`}
        />
        <meta
          property="og:description"
          content={article && article.description}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@RobotTraders" />
        <meta name="twitter:title" content={article && article.title} />
        <meta
          name="twitter:description"
          content={article && article.description}
        />
      </Helmet>
      <h1>{article && article.title}</h1>
      <div className="OneArticle-icons">
        <span className="OneArticle-icons-span">
          <FontAwesomeIcon icon={faRobot} />
          <p>Robot Traders</p>
        </span>
        <span className="OneArticle-icons-span">
          <FontAwesomeIcon icon={faCalendarDays} />
          <p>{day}</p>
        </span>
        <span className="OneArticle-icons-span">
          <FontAwesomeIcon icon={faClock} />
          <p>{`${readingTime} min`}</p>
        </span>
      </div>
      <p>{article && article.description}</p>
      <img
        src={`https://hammerhead-app-atzdr.ondigitalocean.app${
          article && article.picture.data.attributes.url
        }`}
        className="OneArticle-img"
      />
      <ReactMarkdown
        className="OneArticle-section"
        remarkPlugins={[remarkMath]}
        rehypePlugins={[
          rehypeKatex,
          [
            rehypeReact,
            {
              createElement: React.createElement,
              components: { mdx_yt: YouTubeEmbed },
            },
          ],
        ]}
        children={article && article.article}
        components={{
          p: ({ node, children, ...props }) => {
            // Vérifiez si le paragraphe contient un composant MDX_YT
            const childText =
              children;
            if (
              childText &&
              typeof childText === 'string' &&
              childText.includes('<MDX_YT')
            ) {
              const videoIdMatch = childText.match(/videoId="([^"]+)"/);
              const videoId = videoIdMatch[1];
              return <YouTubeEmbed videoId={videoId} />;
            }
            return <p {...props}>{children}</p>;
          },
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            const codeContent = String(children).replace(/\n$/, '');
            if (!inline && match) {
              const clipboardId = `code-clipboard-${Math.random()}`.replace(
                /\./g,
                '_'
              );

              const handleClick = () => {
                const codeElement = document.getElementById(clipboardId);
                toast.success('Copied to clipboard!', {
                  position: 'bottom-center',
                  autoClose: 1000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  theme: 'dark',
                });

                codeElement.select(); // Sélectionnez le texte dans le textarea
                document.execCommand('copy'); // Copiez le texte
              };

              return (
                <div>
                  <SyntaxHighlighter
                    {...props}
                    children={codeContent}
                    style={dracula}
                    language={match[1]}
                    PreTag="div"
                  />
                  <button
                    style={{ marginTop: '10px' }}
                    onClick={handleClick}
                    className="Copy"
                  >
                    Copy
                  </button>
                  <textarea
                    id={clipboardId}
                    style={{ position: 'absolute', left: '-9999px' }}
                    readOnly
                    value={codeContent}
                  />
                </div>
              );
            } else {
              return (
                <code {...props} className={className}>
                  {children}
                </code>
              );
            }
          },
        }}
      >
        {article && article.article}
      </ReactMarkdown>
      <Link to="/blog">
        <img src={arrow} style={{ width: '40px' }} />
      </Link>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="dark"
      />
    </div>
  );
}

export default OneArticle;
