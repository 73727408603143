import './OneCourse.scss';

import { useEffect, useState } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import emoji from 'remark-emoji';

import arrow from '../../Components/Assets/arrow.png';
import Axios from '../../Utils/Axios';

function OneCourse() {
  const [course, setCourse] = useState([]);
  const { slug} = useParams();

  useEffect(() => {
    Axios.get(`/courses/${slug}?populate=*`)
      .then((res) => {
        setCourse(res.data.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, []);

  return (
    <div className="OneCourse">
      <h1 className="OneCourse-title">
        {course.attributes && course.attributes.title}
      </h1>
      {course.attributes && course.attributes.text && (
        <ReactMarkdown remarkPlugins={[gfm, emoji]} className="OneCourse-section">
          {course.attributes.text}
        </ReactMarkdown>
      )}
      {course.attributes && course.attributes.youtube && (
        <div className="OneCourse-youtube">
          {course.attributes.youtube.map((video) => (
            <iframe
              width="480"
              height="265"
              src={`https://www.youtube.com/embed/${video.youtubeLink}`}
              title={video.title}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          ))}
        </div>
      )}
      <nav className="OneCourse-chapter">
        {course.attributes &&
          course.attributes.course.map((chapter, i) => (
            <NavLink
              key={chapter.id}
              to={`/course/${id}/chapter/${chapter.id}`}
            >
              <p>{i}</p>
              {chapter.title}
            </NavLink>
          ))}
      </nav>
      <Link to="/courses">
        <img src={arrow} style={{ width: '40px' }} />
      </Link>
    </div>
  );
}

export default OneCourse;
